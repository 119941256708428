<script setup
        lang="ts">
import { onMounted }               from 'vue';
import { useLoop, useTresContext } from '@tresjs/core';
import { DragControls }            from 'three/addons/controls/DragControls.js';
import { TextGeometry }            from 'three/addons/geometries/TextGeometry.js';
import { Font, type FontData }     from 'three/addons/loaders/FontLoader.js';
import { changa }                  from './font.ts';

import {
    BoxGeometry,
    BufferGeometry,
    CubicBezierCurve3,
    Vector3,
    Line,
    ShaderMaterial,
    Color,
    EdgesGeometry,
    LineSegments,
    LineBasicMaterial,
} from 'three';

const font = new Font(changa as unknown as FontData);

const www_geometry = new TextGeometry('www', {
    font,
    size:          4,
    depth:         1,
    curveSegments: 1,
});
const www_edges_geometry = new EdgesGeometry(www_geometry);
const www_material = new LineBasicMaterial({
    color: '#87233F',
});
const www_box = new LineSegments(www_edges_geometry, www_material);
www_box.position.set(0, 0, 1);

const si_geometry = new BoxGeometry(2, 2, 2);
const si_edges_geometry = new EdgesGeometry(si_geometry);
const si_material = new LineBasicMaterial({
    color: '#457B9D',
});
const si_box = new LineSegments(si_edges_geometry, si_material);
si_box.position.set(22, 18, 1);

const si_line_curve = new CubicBezierCurve3(
    www_box.position,
    new Vector3(www_box.position.x + 22, www_box.position.y, www_box.position.z),
    new Vector3(www_box.position.x + 22, www_box.position.y, www_box.position.z),
    si_box.position,
);
const si_line_geometry = new BufferGeometry().setFromPoints(si_line_curve.getPoints(50));
const si_line_material = new ShaderMaterial({
    uniforms:       {
        diffuse:  { value: new Color('#76C893') },
        dashSize: { value: 3 },
        gapSize:  { value: 4 },
        dotSize:  { value: 0 },
        opacity:  { value: 0.9 },
        time:     { value: 0 },
    },
    vertexShader:   `
  attribute float lineDistance;
  varying float vLineDistance;

  void main() {
    vLineDistance = lineDistance;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }
  `,
    fragmentShader: `
  uniform vec3 diffuse;
  uniform float opacity;
  uniform float time; // added time uniform

  uniform float dashSize;
  uniform float gapSize;
  uniform float dotSize;
  varying float vLineDistance;

  void main() {
		float totalSize = dashSize + gapSize;
		float modulo = mod( vLineDistance + time, totalSize ); // time added to vLineDistance
    float dotDistance = dashSize + (gapSize * .5) - (dotSize * .5);

    if ( modulo > dashSize && mod(modulo, dotDistance) > dotSize ) {
      discard;
    }

    gl_FragColor = vec4( diffuse, opacity );
  }
  `,
    transparent:    true,
});
const si_line = new Line(si_line_geometry, si_line_material);
si_line.computeLineDistances();

const web_vitals_geometry = new BoxGeometry(4, 2, 2);
const web_vitals_edges_geometry = new EdgesGeometry(web_vitals_geometry);
const web_vitals_material = new LineBasicMaterial({
    color: '#C94B4B',
});
const web_vitals_box = new LineSegments(web_vitals_edges_geometry, web_vitals_material);
web_vitals_box.position.set(4, 16, 1);

const web_vitals_line_curve = new CubicBezierCurve3(
    new Vector3(0, 0, 1),
    new Vector3(www_box.position.x - 18, 0, 1),
    new Vector3(www_box.position.x - 18, 0, 1),
    new Vector3(4, 16, 1),
);
const web_vitals_line_geometry = new BufferGeometry().setFromPoints(web_vitals_line_curve.getPoints(50));
const web_vitals_line_material = new ShaderMaterial({
    uniforms:       {
        diffuse:  { value: new Color('#76C893') },
        dashSize: { value: 3 },
        gapSize:  { value: 4 },
        dotSize:  { value: 0 },
        opacity:  { value: 0.9 },
        time:     { value: 0 },
    },
    vertexShader:   `
  attribute float lineDistance;
  varying float vLineDistance;

  void main() {
    vLineDistance = lineDistance;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }
  `,
    fragmentShader: `
  uniform vec3 diffuse;
  uniform float opacity;
  uniform float time; // added time uniform

  uniform float dashSize;
  uniform float gapSize;
  uniform float dotSize;
  varying float vLineDistance;

  void main() {
		float totalSize = dashSize + gapSize;
		float modulo = mod( vLineDistance + time, totalSize ); // time added to vLineDistance
    float dotDistance = dashSize + (gapSize * .5) - (dotSize * .5);

    if ( modulo > dashSize && mod(modulo, dotDistance) > dotSize ) {
      discard;
    }

    gl_FragColor = vec4( diffuse, opacity );
  }
  `,
    transparent:    true,
});
const web_vitals_line = new Line(web_vitals_line_geometry, web_vitals_line_material);
web_vitals_line.computeLineDistances();

const reshepe_geometry = new BoxGeometry(3, 3, 3);
const reshepe_edges_geometry = new EdgesGeometry(reshepe_geometry);
const reshepe_material = new LineBasicMaterial({
    color: '#E9C46A',
});

const reshepe_box = new LineSegments(reshepe_edges_geometry, reshepe_material);
reshepe_box.position.set(18, 34, 1);

const reshepe_line1_curve = new CubicBezierCurve3(
    si_line_curve.v3,
    new Vector3(si_line_curve.v3.x - 8, si_line_curve.v3.y, si_line_curve.v3.z),
    new Vector3(si_line_curve.v3.x - 8, si_line_curve.v3.y, si_line_curve.v3.z),
    new Vector3(reshepe_box.position.x, reshepe_box.position.y, reshepe_box.position.z),
);
const reshepe_line1_geometry = new BufferGeometry().setFromPoints(reshepe_line1_curve.getPoints(50));
const reshepe_line1_material = new ShaderMaterial({
    uniforms:       {
        diffuse:  { value: new Color('#8AADBC') },
        dashSize: { value: 1 },
        gapSize:  { value: 2 },
        dotSize:  { value: 0 },
        opacity:  { value: 0.9 },
        time:     { value: 0 },
    },
    vertexShader:   `
  attribute float lineDistance;
  varying float vLineDistance;

  void main() {
    vLineDistance = lineDistance;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }
  `,
    fragmentShader: `
  uniform vec3 diffuse;
  uniform float opacity;
  uniform float time; // added time uniform

  uniform float dashSize;
  uniform float gapSize;
  uniform float dotSize;
  varying float vLineDistance;

  void main() {
		float totalSize = dashSize + gapSize;
		float modulo = mod( vLineDistance + time, totalSize ); // time added to vLineDistance
    float dotDistance = dashSize + (gapSize * .5) - (dotSize * .5);

    if ( modulo > dashSize && mod(modulo, dotDistance) > dotSize ) {
      discard;
    }

    gl_FragColor = vec4( diffuse, opacity );
  }
  `,
    transparent:    true,
});
const reshepe_line1 = new Line(reshepe_line1_geometry, reshepe_line1_material);
reshepe_line1.computeLineDistances();

const reshepe_line2_curve = new CubicBezierCurve3(
    web_vitals_line_curve.v3,
    new Vector3(web_vitals_line_curve.v3.x + 8, web_vitals_line_curve.v3.y, web_vitals_line_curve.v3.z),
    new Vector3(web_vitals_line_curve.v3.x + 8, web_vitals_line_curve.v3.y, web_vitals_line_curve.v3.z),
    new Vector3(reshepe_box.position.x, reshepe_box.position.y, reshepe_box.position.z),
);
const reshepe_line2_geometry = new BufferGeometry().setFromPoints(reshepe_line2_curve.getPoints(50));
const reshepe_line2_material = new ShaderMaterial({
    uniforms:       {
        diffuse:  { value: new Color('#8AADBC') },
        dashSize: { value: 1 },
        gapSize:  { value: 2 },
        dotSize:  { value: 0 },
        opacity:  { value: 0.9 },
        time:     { value: 0 },
    },
    vertexShader:   `
  attribute float lineDistance;
  varying float vLineDistance;

  void main() {
    vLineDistance = lineDistance;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }
  `,
    fragmentShader: `
  uniform vec3 diffuse;
  uniform float opacity;
  uniform float time; // added time uniform

  uniform float dashSize;
  uniform float gapSize;
  uniform float dotSize;
  varying float vLineDistance;

  void main() {
		float totalSize = dashSize + gapSize;
		float modulo = mod( vLineDistance + time, totalSize ); // time added to vLineDistance
    float dotDistance = dashSize + (gapSize * .5) - (dotSize * .5);

    if ( modulo > dashSize && mod(modulo, dotDistance) > dotSize ) {
      discard;
    }

    gl_FragColor = vec4( diffuse, opacity );
  }
  `,
    transparent:    true,
});
const reshepe_line2 = new Line(reshepe_line2_geometry, reshepe_line2_material);
reshepe_line2.computeLineDistances();

const { camera, renderer } = useTresContext();

const { onBeforeRender } = useLoop();

onBeforeRender(({ delta }) => {
    si_line_material.uniforms.time.value += delta * 13;
    si_line_material.uniforms.opacity.value = Math.random() * 0.1 + 0.9;
    web_vitals_line_material.uniforms.time.value += delta * 12;
    web_vitals_line_material.uniforms.opacity.value = Math.random() * 0.1 + 0.9;
    reshepe_line1_material.uniforms.time.value += delta * 11;
    reshepe_line1_material.uniforms.opacity.value = Math.random() * 0.1 + 0.9;
    reshepe_line2_material.uniforms.time.value += delta * 11;
    reshepe_line2_material.uniforms.opacity.value = Math.random() * 0.1 + 0.9;
});

const emit = defineEmits<{
    (e: 'dragStart'): void;
    (e: 'dragEnd'): void;
}>();

const redraw = () => {
    emit('dragStart');

    reshepe_line1.geometry.setFromPoints(new CubicBezierCurve3(
        si_line_curve.v3,
        new Vector3(si_line_curve.v3.x - 8, si_line_curve.v3.y, si_line_curve.v3.z),
        new Vector3(si_line_curve.v3.x - 8, si_line_curve.v3.y, si_line_curve.v3.z),
        new Vector3(reshepe_box.position.x + 1, reshepe_box.position.y, reshepe_box.position.z),
    ).getPoints(50));
    reshepe_line1.computeLineDistances();

    reshepe_line2.geometry.setFromPoints(new CubicBezierCurve3(
        web_vitals_box.position,
        new Vector3(web_vitals_line_curve.v3.x + 8, web_vitals_line_curve.v3.y, web_vitals_line_curve.v3.z),
        new Vector3(web_vitals_line_curve.v3.x + 8, web_vitals_line_curve.v3.y, web_vitals_line_curve.v3.z),
        new Vector3(reshepe_box.position.x, reshepe_box.position.y, reshepe_box.position.z),
    ).getPoints(50));
    reshepe_line2.computeLineDistances();

    web_vitals_line.geometry.setFromPoints(new CubicBezierCurve3(
        www_box.position,
        new Vector3(www_box.position.x - 18, www_box.position.y, www_box.position.z),
        new Vector3(www_box.position.x - 18, www_box.position.y, www_box.position.z),
        web_vitals_box.position,
    ).getPoints(50));
    web_vitals_line.computeLineDistances();

    si_line.geometry.setFromPoints(new CubicBezierCurve3(
        www_box.position,
        new Vector3(www_box.position.x + 22, www_box.position.y, www_box.position.z),
        new Vector3(www_box.position.x + 22, www_box.position.y, www_box.position.z),
        si_box.position,
    ).getPoints(50));
};

onMounted(() => {
    const www_box_controls = new DragControls([www_box], camera.value!, renderer.value.domElement);

    www_box_controls.addEventListener('drag', () => {
        redraw();
    });

    www_box_controls.addEventListener('dragend', () => {
        emit('dragEnd');
    });

    const si_box_controls = new DragControls([si_box], camera.value!, renderer.value.domElement);

    si_box_controls.addEventListener('drag', () => {
        redraw();
    });

    si_box_controls.addEventListener('dragend', () => {
        emit('dragEnd');
    });

    const web_vitals_box_controls = new DragControls([web_vitals_box], camera.value!, renderer.value.domElement);

    web_vitals_box_controls.addEventListener('drag', () => {
        redraw();
    });

    web_vitals_box_controls.addEventListener('dragend', () => {
        emit('dragEnd');
    });

    const reshepe_box_controls = new DragControls([reshepe_box], camera.value!, renderer.value.domElement);

    reshepe_box_controls.addEventListener('drag', () => {
        redraw();
    });

    reshepe_box_controls.addEventListener('dragend', () => {
        emit('dragEnd');
    });
});
</script>

<template>
    <primitive :object="www_box" />
    <primitive :object="si_box" />
    <primitive :object="web_vitals_box" />
    <primitive :object="reshepe_box" />
    <primitive :object="si_line" />
    <primitive :object="web_vitals_line" />
    <primitive :object="reshepe_line1" />
    <primitive :object="reshepe_line2" />
</template>

<style lang="scss"
       scoped>
// nothing here
</style>
